import {
  ProductDetailsDto,
  ProductSustainabilityAttributeDto,
  ProductSustainabilityCategoryEnum,
  SbuInfoEnum,
} from '../../api';

export const mockProductDetails: ProductDetailsDto = getPrimary();
export const mockProductDetails2: ProductDetailsDto = getSecondary();

function getPrimary(): ProductDetailsDto {
  const primary: ProductDetailsDto = {
    id: '30502414',
    name: 'Magnafloc® 1425',
    slug: 'magnafloc-1425',
    description: null,
    technicalName: null,
    alphaCode: null,
    productGroup: null,
    sbu: SbuInfoEnum.Evh,
    hasSample: false,
    productAttributes: [],
    buyability: true,
    isBmbCertified: false,
    sustainabilityAttributes: getAllSustainabilityAttributes(),
    articles: [
      {
        id: '50136070',
        name: 'Magnafloc® 1425 10kg PE-Can',
        unitOfMeasure: 'KG',
        visibleUnitOfMeasure: '公斤',
        packagingSizeText: '',
        packagingType: 'BULK',
        buyability: true,
        customerArticleId: 'mag-1425-10kg',
      },
      {
        id: '50136111',
        name: 'Magnafloc® 1425 1080KG Composite - IBC (PE/Metal)',
        unitOfMeasure: 'KG',
        visibleUnitOfMeasure: '公斤',
        packagingSizeText: '1080KG Composite - IBC (PE/Metal)',
        packagingType: 'VERP',
        buyability: false,
        customerArticleId: null,
      },
      {
        id: '50136112',
        name: 'Magnafloc® 1425 300 Composite - IBC (PE/Metal)',
        unitOfMeasure: 'KG',
        visibleUnitOfMeasure: '公斤',
        packagingSizeText: '300KG Composite - IBC (PE/Metal)',
        packagingType: 'VERP',
        buyability: true,
        customerArticleId: null,
      },
    ],
    isInThickenerExplorer: true,
  };

  return primary;
}

function getSecondary(): ProductDetailsDto {
  const secondary: ProductDetailsDto = {
    id: '123',
    name: 'Irganox1010',
    slug: 'irganox-1010',
    description: 'this is dummy description',
    technicalName: 'Antioxidant Irganox 1010',
    alphaCode: 'ABC',
    productGroup: 'AB',
    sbu: SbuInfoEnum.Evk,
    lastPurchasedDate: new Date().toISOString(),
    hasSample: true,
    buyability: false,
    isBmbCertified: false,
    articles: [
      {
        id: '50023213',
        name: 'Irganox 1010 5KG Sample',
        unitOfMeasure: 'KGM',
        visibleUnitOfMeasure: '公斤',
        packagingSizeText: '5KG Plastic Container (13H3)',
        packagingType: 'BULK',
        buyability: false,
        customerArticleId: 'irg-1010-5kg',
      },
      {
        id: '50023213',
        name: 'Irganox 1010 5KG Sample',
        unitOfMeasure: 'KGM',
        visibleUnitOfMeasure: '公斤',
        packagingSizeText: '5KG Plastic Container (13H3)',
        packagingType: 'BULK',
        buyability: false,
        customerArticleId: null,
      },
      {
        id: '50023214',
        name: 'Irganox 1010 220KG Packaging',
        unitOfMeasure: 'KGM',
        visibleUnitOfMeasure: '公斤',
        packagingSizeText: '220KG Plastic drums',
        packagingType: 'BULK',
        buyability: false,
        customerArticleId: null,
      },
    ],
    isInThickenerExplorer: false,
    productAttributes: [],
    sustainabilityAttributes: getAllSustainabilityAttributes(),
  };

  return secondary;
}

function getAllSustainabilityAttributes(): ProductSustainabilityAttributeDto[] {
  return [
    {
      name: 'Increased quality of product	',
      id: '8a8081b98902bf3501891f3b229136a5',
      category: ProductSustainabilityCategoryEnum.Biodegradability,
    },
    {
      name: 'Enables conservation of biodiversity (e.g. less deforestation or overfishing)	',
      id: '8a8081b98902bf3501891f3d886036ae',
      category: ProductSustainabilityCategoryEnum.Biodiversity,
    },
    {
      name: 'Reduced environmental exposure due to optimized application technology (e.g. bait technology)	',
      id: '8a8081b98902bf3501891f540ee23796',
      category: ProductSustainabilityCategoryEnum.Renewables,
    },
    {
      name: 'More favorable behavior (e.g. less leaching)',
      id: '8a8081b98902bf3501891f54ca0e379e',
      category: ProductSustainabilityCategoryEnum.EcoCertification,
    },
    {
      name: 'Higher compatibility with low-drift technologies',
      id: '8a8081b98902bf3501891f53dc593794',
      category: ProductSustainabilityCategoryEnum.ResourceEfficiency,
    },
    {
      name: 'With improved ecotox profile',
      id: '8a8081b98902bf3501891f549f5f379c',
      category: ProductSustainabilityCategoryEnum.Emissions,
    },
    {
      name: 'Positive effect on diversity of ecosystems (e.g. soil organisms)',
      id: '8a8081b98902bf3501891f53ac563792',
      category: ProductSustainabilityCategoryEnum.HealthAndSafety,
    },
    {
      name: 'Integrated resistance management: unique position with new mode of action (MOA), curative control of sudden disease outbreaks, disruptive/shifting type/multisite MOA',
      id: '8a8081b98902bf3501891f5472a3379a',
      category: ProductSustainabilityCategoryEnum.Durability,
    },
    {
      name: 'Solution enables cost savings for growers, plant raisers, processors, and retailers',
      id: '8a8081b98902bf3501891f3c3a2c36aa',
      category: ProductSustainabilityCategoryEnum.SustainableDevelopmentGoals,
    },
    {
      name: 'Reduced land use',
      id: '8a8081b98902bf3501891f543e213798',
      category: ProductSustainabilityCategoryEnum.ResourceAndEcosystems,
    },
    {
      name: 'Solution enables cost savings by the farmer and/or along the value chain',
      id: '8a8081b98902bf3501891f3c083836a8',
      category: ProductSustainabilityCategoryEnum.ClimateChange,
    },
    {
      name: 'Reduced risk for non-target organisms or beneficial (e.g. Increased specificity)',
      id: '8a8081b98902bf3501891f3dc16536b0',
      category: ProductSustainabilityCategoryEnum.Water,
    },
    {
      name: 'Enables cost savings downstream and/or along the value chain, e.g., through less energy intensive processes.',
      id: '8a80819186175a4f018617dc5e695fb3',
      category: ProductSustainabilityCategoryEnum.WasteReduction,
    },
    {
      name: 'Enables cost savings downstream and/or along the value chain, e.g., through less energy intensive processes.',
      id: '8a80819186175a4f018617dc5e695fb3',
      category: ProductSustainabilityCategoryEnum.CostSavings,
    },
    {
      name: 'Enables cost savings downstream and/or along the value chain, e.g., through less energy intensive processes.',
      id: '8a80819186175a4f018617dc5e695fb3',
      category: ProductSustainabilityCategoryEnum.Energy,
    },
    {
      name: 'Enables cost savings downstream and/or along the value chain, e.g., through less energy intensive processes.',
      id: '8a80819186175a4f018617dc5e695fb3',
      category: ProductSustainabilityCategoryEnum.Sun,
    },
    {
      name: 'Enables cost savings downstream and/or along the value chain, e.g., through less energy intensive processes.',
      id: '8a80819186175a4f018617dc5e695fb3',
      category: ProductSustainabilityCategoryEnum.Windmill,
    },
  ];
}
